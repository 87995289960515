<template>
    <div>
        <embed :src="url" class="iframe slot"/>
    </div>
</template>

<script>
    import {CASINO} from "@/config/casino_confs";
    import countryToCurrency from "country-to-currency";
    import { mapGetters } from "vuex";

    export default {
        data(){
            return{
                url:""
            }
        },
        computed: {
            ...mapGetters({
                geo:'geo',
                user: 'user',
            }),
            currency() {
                if(this.user) {
                    return (this.user.iso == null) ? 'USD' : countryToCurrency[this.user.iso]
                } else {
                    return (this.geo == null) ? 'USD' : countryToCurrency[this.geo?.country_code2]
                }
            },
        },
        async mounted(){
            const gameId = this.$route.params.gameId
            const data = await CASINO.launchGameL(gameId,this.currency.toUpperCase(),this.$i18n.locale,this.geo?.country_code2,'REAL');
            this.url = data.gameURL;
            // this.url = CASINO.launchGameLive(gameId,this.currency.toUpperCase(),this.$i18n.locale,this.geo?.country_code3)
        }
    }
</script>

<style >
    .iframe {
        background: #000;
        border: none;
        width: 100%;
        height: 85vh;
    }
    /* embed {
        height: 100vh;
        width: 100vw;
    } */
</style>